require("cropperjs/dist/cropper");
require("jquery-cropper/dist/jquery-cropper");
require("jquery-ui/ui/widget");
require("jquery-ui/ui/widgets/autocomplete");
require("select2");
require("blueimp-file-upload/js/jquery.fileupload");
require("blueimp-file-upload/js/jquery.fileupload-ui");
require("bootstrap-tagsinput/dist/bootstrap-tagsinput");
require("bootstrap-datepicker/dist/js/bootstrap-datepicker.min");
require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.en-GB.min");
require("cocoon-js");
require("../morris/index");
require("chart.js/dist/chart");

require("./image_cropper");
require("./responsive");
require("./campaigns/main");
require("./reportings/main");
require("./market/main");
require("./popup");
require("./inputSlider");
require("./modal");
require("./networkAuth");
require("./direct_upload");
require("./jQueryScripts");

import { basicTriggerInit } from "./basicTrigger";
import { aspectRatioOnResize } from "./campaigns/aspectRatioCalc";
import { minimizeCampaignsFilter } from "./campaigns/minimizeCampaignsFilter";
import { countersInit } from "./counter";
import { crmScriptsInit } from "./crmScripts";
import { forceReloadInit } from "./forceReload";
import { graphOnLoad } from "./graphOnLoad";
import { gtmPushInit } from "./gtmPush";
import { textAreaHeightInit } from "./textAreaHeight";
import { tooltipsInit } from "./tooltip";
import { tabsInit } from "./tabs";
import { turboLinksInit } from "./turboLinks";
import { flashMessagesInit } from "./flashMessages";
import { dragAndDropInit } from "./dragAndDrop";
import { localStorageVisibilityInit } from "./localStorageVisibility";
import { openCrmActionInit } from "./openCrmAction";
import smoothscroll from "smoothscroll-polyfill";

$(document).on("turbolinks:load", function () {
  $(
    "input[data-role=tagsinput], select[multiple][data-role=tagsinput]"
  ).tagsinput();
  $("select.select2").select2({ width: "100%" });

  crmScriptsInit();
  aspectRatioOnResize();
  minimizeCampaignsFilter();
  textAreaHeightInit();
  countersInit();
  tooltipsInit();
  graphOnLoad();
  gtmPushInit();
  basicTriggerInit();
  tabsInit();
  forceReloadInit();
  flashMessagesInit();
  openCrmActionInit();

  smoothscroll.polyfill();

  const timestamp = localStorage.getItem("database-modal");
  if (timestamp) {
    const closed = new Date(timestamp);
    closed.setDate(closed.getDate() + 7);

    if (closed < new Date()) {
      $("#database-modal-trigger").click();
    }
  } else {
    $("#database-modal-trigger").click();
  }
});

$(document).on("change", ".tac_payment", function () {
  if ($(this).is(":checked")) {
    $(this).closest("form").find("input[type=submit]").attr("disabled", null);
  } else {
    $(this).closest("form").find("input[type=submit]").attr("disabled", "");
  }
  dragAndDropInit();
  localStorageVisibilityInit();
});

window.addEventListener("resize", minimizeCampaignsFilter);
