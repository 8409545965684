require.context("../images", true);
let jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("@rails/ujs").start();

import Turbolinks from "turbolinks";
Turbolinks.start();
Turbolinks.setProgressBarDelay(100);
